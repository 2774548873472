/* .apexcharts-toolbar{
    display: none !important;
} */


    /* @font-face {
        font-family: 'Chakra Petch';
        src: url('./scss/Chakra_Petch/ChakraPetch-SemiBold.ttf') format('truetype');
        font-weight: 600; 
        font-style: normal;
    } */

    /* body{
        font-family: "ChakraPetch",   !important;
    } */
    @import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

    body {
        font-family: "Chakra Petch", sans-serif;
        font-weight: 600;
        font-style: normal;
      }


    .product_border{
        border:2px solid gray;
        border-radius: 5px;
        
    }

    .top-products-table > tbody > tr{
    margin-bottom: 12px;
    display: block;
    border-radius: 12px;
    padding: 20px 16px 16px 16px;
    }


    .tabsbottomdata {
        border-bottom: 2px solid red;
      }

      .recipetabs{
        color: white;
      }


      .recipe-space .col-12{
            margin-top: 12px;
      }
      
/* select > option{
    background-color: #1D2835;
    color: white;
} */

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center, .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
    justify-content: right ;
}

.react-select__menu{
    z-index: 9999 !important;
}

.css-qbdosj-Input{
    color: whitesmoke;
}