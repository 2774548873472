

.containerwrapper{
  display: flex;
  margin: auto auto auto auto;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width:72%;
  
    /* border:1px solid red */
  }

.logincard{
  /* margin-top: 10px; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  padding: 10%;
  /* border:1px solid red */
}

.carddata{
  width:100%
}


.mainimage{
  height: 100%;
  width:100%;
  border-radius: 20px;
  margin: 0 0 0 auto;
  /* padding: auto auto 6% 13%; */
  /* border:1px solid red */
}





@media (max-width: 767px) {
  .containerwrapper{
    display: flex;
    /* margin: -11% auto auto auto; */
    /* border:1px solid red; */
      justify-content: center;
      align-items: center;
      height: 100vh;
      width:98%;
      /* padding: 10% 0 6% 0; */
    }
  
      .logincard{
        height: 100%;
        padding: 5px 5% 6% 5%;
      }
    
      /* .d-md-block {
        display: none;
      } */
    }

@media (max-width: 480px) {
  .containerwrapper{
    display: flex;
    margin: -11% auto auto auto;
    /* border:1px solid red; */
    justify-content: center;
    align-items: center;
    height: 100vh;
    width:98%;
    /* padding: 10% 0 6% 0; */
  }
  
  .logincard{
    height: 100%;
    padding: 5px 5% 6% 5%;
  }
  
  .d-md-block {
    display: none; /* Hide image on mobile */
  }
}
