@mixin bootstrap-icons() {
	display: inline-block;
	font-family: bootstrap-icons!important;
	font-style: normal;
	font-weight: 400!important;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	vertical-align: -0.125em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}